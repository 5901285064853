export type Return = {
  ok?: boolean;
  message?: string;
  data?: any;
};

export const makeRequest = async (
  url: string,
  method: string,
  headers: { [key: string]: string } = {},
  body?: { [key: string]: any },
): Promise<Return> => {
  const responseState: Return = {};
  try {
    let response;
    if (body) {
      response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ ...body }),
      });
    } else {
      response = await fetch(url, {
        method,
        headers,
      });
    }

    if (response.ok) {
      const data = await response.json();
      responseState.ok = true;
      responseState.message = data.message;
      responseState.data = data.data || data;
    } else {
      const err = await response.json();
      throw new Error(err.message);
    }
  } catch (err) {
    responseState.ok = false;
    responseState.message = err.message;
  }
  return responseState;
};
