import { ref } from 'vue';
import { auth } from '../store';
const savedUserToken = localStorage.getItem('user-token');

export default function useRequestHeaders() {
  const headers = ref(new Headers());

  if (auth.token) {
    headers.value.append('Authorization', `Bearer ${savedUserToken}`);
  }

  return { headers };
}

export function useAuthorizedHeaders() {
  const headers: any = { 'Content-Type': 'application/json' };

  if (savedUserToken) {
    headers['Authorization'] = `Bearer ${savedUserToken}`
  }
  if (!savedUserToken) {
    headers['Authorization'] = `Bearer ${auth.state.token}`
  }

  return headers;
}

export function useUnAuthorizedHeaders() {

  return { 'Content-Type': 'application/json' };
}
