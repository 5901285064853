import { computed, reactive } from 'vue';

import { getConfig } from '@/lib/constants';
import { makeRequest, Return } from '@/lib/utils';
import { merchants } from '@/store'

type AuthState = {
  token: string | null;
};

const state = reactive<AuthState>({
  token: null,
});

const token = computed(() => state.token);

const loginUser2FA = async (email: string): Promise<Return> => {
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/login2fa`,
    'POST',
    {'Content-Type': 'application/json'},
    {email}
  )
  return response
}

const passwordLogin = async (identifier: string, password: string): Promise<Return> => {
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/login`,
    'POST',
    {'Content-Type': 'application/json'},
    { identifier, password }
  )
  if (response.ok) {
    setUserToken(response.data.jwt)
    merchants.setMerchantProfile(response.data.user)
    merchants.updateMerchantLocalStorage()
  }
  return response
}

const forgotPassword = async (identifier: string): Promise<Return> => {
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/forgot-password`,
    'POST',
    {'Content-Type': 'application/json'},
    { identifier }
  )
  return response
}

const resetPassword = async (password: string, confirmPassword: string, token: string): Promise<Return> => {
  console.log(confirmPassword)
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/reset-password`,
    'PUT',
    {'Content-Type': 'application/json'},
    { password, confirmPassword, token }
  )
  if (response.ok) {
    setUserToken(response.data.jwt)
    merchants.setMerchantProfile(response.data.user)
    merchants.updateMerchantLocalStorage()
  }
  return response
}

const confirm2FACode = async (email: string, code: string): Promise<Return> => {
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/confirm2fa`,
    'POST',
    {'Content-Type': 'application/json'},
    { email, code }
  )
  if (response.ok) {
    setUserToken(response.data.jwt)
    merchants.setMerchantProfile(response.data.user)
    merchants.updateMerchantLocalStorage()
  }
  return response
}

const setUserToken = ( jwt: string ): void => {
  state.token = jwt;
  localStorage.setItem('user-token', jwt);
};

const clearUser = (): void => {
  state.token = null;
  localStorage.removeItem('user-token');
  localStorage.removeItem('dlvr-agent');
};

// const savedUserToken = localStorage.getItem('user-token');

// if (savedUserToken) {
//   setUserToken(savedUserToken);
// }

const store = {
  state,
  token,
  setUserToken,
  clearUser,
  loginUser2FA,
  confirm2FACode,
  passwordLogin,
  forgotPassword,
  resetPassword
};

export default store;
