import { reactive } from 'vue';

import {
  useAuthorizedHeaders,
  useUnAuthorizedHeaders,
} from '@/composables/useRequestHeaders';
import { getConfig } from '@/lib/constants';
import { makeRequest, Return } from '@/lib/utils';
import {
  Merchant,
  Pricing,
  Accounts,
  Account,
} from '@/types/merchant';

const MERCHANT_STORE_KEY = 'dlvr-merchant';

type MerchantState = {
  merchant: Merchant | null;
};

const state = reactive<MerchantState>({
  merchant: null,
});

const setMerchantProfile = (merchant: Merchant): void => {
  state.merchant = merchant;
};

export const updateState = (): void => {
  const localMerchant = localStorage.getItem(MERCHANT_STORE_KEY);
  if (localMerchant) {
    const parsedData = JSON.parse(localMerchant);
    setMerchantProfile(parsedData);
  }
};

const updateMerchantLocalStorage = (): void =>
  localStorage.setItem(
    MERCHANT_STORE_KEY,
    JSON.stringify(state.merchant),
  );

const updateMerchantStore = (merchant: Merchant): void => {
  state.merchant = {
    ...state.merchant,
    ...merchant,
  };
  updateMerchantLocalStorage();
};
const updateLocalStoragePricings = (payload: Pricing[]): void => {
  state.merchant = {
    ...state.merchant,
    pricings: payload,
  };
  updateMerchantLocalStorage();
};
const updateLocalStorageAccounts = (payload: {
  default: string;
  all: Accounts;
  sub_account?: any;
}): void => {
  state.merchant = {
    ...state.merchant,
    accounts: payload,
  };
  updateMerchantLocalStorage();
};

const retrieveMechantProfile = async (): Promise<Return | void> => {
  const store = localStorage.getItem(MERCHANT_STORE_KEY);
  if (store) {
    setMerchantProfile(JSON.parse(store));
    return;
  }
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/auth-merchant`,
    'GET',
    headers,
  );

  if (response.ok) {
    setMerchantProfile(response.data);
    updateMerchantLocalStorage();
  }

  return response;
};

const updateMerchantProfile = async (
  details: Merchant,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles`,
    'PUT',
    headers,
    { ...details },
  );
  if (response.ok) {
    details.slug = details.companyName
      ?.toLowerCase()
      .split(' ')
      .join('_');
    updateMerchantStore({ ...details });
    console.log(response.data);
  }
  return response;
};

const loadAllJobs = async (): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs`,
    'GET',
    headers,
  );

  return response;
};

const getJobById = async (id: string | number): Promise<Return> =>
  await makeRequest(`${getConfig().BASE_URL}/jobs/${id}`, 'GET');

const loadAllTransactions = async (): Promise<Return> => {
  const localMerchant = localStorage.getItem(MERCHANT_STORE_KEY);
  let subaccount: string;
  if (localMerchant) {
    const parsedData = JSON.parse(localMerchant);
    subaccount = parsedData?.accounts?.sub_account?.subaccount_code;
  } else {
    subaccount = '';
  }
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/transactions?subaccount=${subaccount}`,
    'GET',
    headers,
  );

  return response;
};

const getMerchantPricings = async (): Promise<Return> => {
  const localMerchant = localStorage.getItem(MERCHANT_STORE_KEY);
  if (localMerchant) {
    const data: any = {};
    const parsedData = JSON.parse(localMerchant);
    if (parsedData.pricings) {
      data.ok = true;
      data.data = parsedData.pricings;
      return data;
    }
  }
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-pricings`,
    'GET',
    headers,
  );
  if (response.ok) {
    updateLocalStoragePricings(response.data);
  }
  return response;
};

const addMerchantPricing = async (
  payload: Pricing,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-pricings`,
    'POST',
    headers,
    { ...payload },
  );
  if (response.ok) {
    const pricing = state.merchant?.pricings;
    if (pricing) {
      pricing?.push(payload);
      updateLocalStoragePricings(pricing);
    }
  }
  return response;
};

const updateMerchantPricing = async (
  payload: Pricing,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-pricings/${payload.state}`,
    'PUT',
    headers,
    { ...payload },
  );
  if (response.ok) {
    const pricing = state.merchant?.pricings;
    if (pricing) {
      const idx = pricing.findIndex(
        elm => elm.state === payload.state,
      );
      pricing[idx] = payload;
      updateLocalStoragePricings(pricing);
    }
  }
  return response;
};

const updateJobStatus = async (
  id: number,
  status: string,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs/${id}`,
    'PUT',
    headers,
    { status },
  );
  return response;
};

const getListOfNigerianBanks = async (): Promise<Return> => {
  const bankList = localStorage.getItem('bankList');
  if (bankList) {
    const parsedData = JSON.parse(bankList);
    return {
      ok: true,
      data: parsedData,
    };
  }
  const headers = useUnAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/bank-accounts/all-banks`,
    'GET',
    headers,
  );
  if (response.ok) {
    localStorage.setItem(
      'bankList',
      JSON.stringify(response.data.data),
    );
  }
  return response.data;
};

const getMerchantAccounts = async (): Promise<Return> => {
  const localMerchant = localStorage.getItem(MERCHANT_STORE_KEY);
  if (localMerchant) {
    const data: any = {};
    const parsedData = JSON.parse(localMerchant);
    if (parsedData.accounts) {
      data.ok = true;
      data.data = parsedData.accounts;
      return data;
    }
  }
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/bank-accounts/all`,
    'GET',
    headers,
  );
  if (response.ok) {
    if (response.data?.default) {
      updateLocalStorageAccounts(response.data);
    }
  }
  return response;
};

const addNewMerchantAccount = async (data: {
  bankName: string;
  bankCode: string;
  accountNumber: number;
}): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  let response: any;
  if (!state.merchant?.accounts) {
    response = await makeRequest(
      `${getConfig().BASE_URL}/bank-accounts`,
      'POST',
      headers,
      {
        data: { ...data, businessName: state.merchant?.companyName },
      },
    );
    if (response.ok) {
      const { newAccount, sub_account } = response.data;
      updateLocalStorageAccounts({
        all: {
          [newAccount.accountNumber]: newAccount,
        },
        default: newAccount.accountNumber,
        sub_account,
      });
    }
    return response;
  }
  response = await makeRequest(
    `${getConfig().BASE_URL}/bank-accounts`,
    'POST',
    headers,
    { data },
  );
  if (response.ok) {
    const newAccount = response.data;
    updateLocalStorageAccounts({
      ...state.merchant.accounts,
      all: {
        ...state.merchant.accounts.all,
        [newAccount.accountNumber]: newAccount,
      },
    });
  }
  return response;
};

const setDefaultMerchantAccount = async (
  bank: Account,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const { accountNumber, bankCode } = bank;
  const response = await makeRequest(
    `${getConfig().BASE_URL}/bank-accounts`,
    'PUT',
    headers,
    {
      accountNumber,
      bankCode,
      subAccountCode: state.merchant?.accounts?.sub_account
        .subaccount_code as string,
    },
  );
  if (response.ok) {
    if (state.merchant?.accounts) {
      updateLocalStorageAccounts({
        ...state.merchant.accounts,
        default: accountNumber.toString(),
      });
    }
    return response;
  }
  return response;
};

const deleteMerchantAccount = async (
  accountNumber: string,
): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${
      getConfig().BASE_URL
    }/bank-accounts?accountNumber=${accountNumber}`,
    'DELETE',
    headers,
  );
  if (response.ok) {
    if (state.merchant?.accounts) {
      const remainingAccounts = Object.entries(
        state.merchant.accounts?.all,
      ).reduce((acc: Accounts, account): Accounts => {
        if (account[0] !== accountNumber)
          acc[account[0]] = account[1];
        return acc;
      }, {});
      updateLocalStorageAccounts({
        ...state.merchant.accounts,
        all: remainingAccounts,
      });
    }
  }
  return response;
};

const deletePricing = async (priceState: string): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-pricings/${priceState}`,
    'DELETE',
    headers,
  );
  if (response.ok) {
    if (state.merchant?.pricings) {
      const remainingPricings = state.merchant.pricings.reduce(
        (acc: Pricing[], price: Pricing) => {
          if (price.state !== priceState) acc.push(price);
          return acc;
        },
        [],
      );
      updateLocalStoragePricings(remainingPricings);
    }
  }
  return response;
};

const getUploadUrl = async (imageName: string): Promise<Return> => {
  const headers = useAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-uploadUrl/${imageName}`,
    'GET',
    headers,
  );
  return response;
};

const clearMerchantProfile = (): void => {
  state.merchant = null;
  localStorage.removeItem(MERCHANT_STORE_KEY);
  localStorage.removeItem('user-token');
};

const store = {
  state,
  getUploadUrl,
  loadAllTransactions,
  retrieveMechantProfile,
  deletePricing,
  loadAllJobs,
  getJobById,
  updateMerchantStore,
  updateJobStatus,
  setMerchantProfile,
  clearMerchantProfile,
  updateMerchantLocalStorage,
  updateMerchantProfile,
  getListOfNigerianBanks,
  getMerchantAccounts,
  addNewMerchantAccount,
  setDefaultMerchantAccount,
  deleteMerchantAccount,
  getMerchantPricings,
  addMerchantPricing,
  updateMerchantPricing,
};

export default store;
