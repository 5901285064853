import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import { updateState } from '@/store/merchants';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/Home/Home.vue'),
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('../views/PasswordLogin.vue'),
  },
  {
    path: '/forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/:slug/book',
    component: () => import('../views/BookingForm/BookingForm.vue'),
  },
  {
    path: '/book',
    component: () => import('../views/BookingForm/BookingForm.vue'),
  },
  {
    path: '/orderstatus/:id',
    component: () => import('../views/Status.vue'),
  },
  {
    path: '/orderconfirmation/:id',
    component: () => import('../views/JobConfirmation.vue'),
  },
  {
    path: '/reactivate',
    component: () => import('../views/Reactivate.vue'),
  },
  {
    path: '/app',
    component: () => import('../layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      const savedUserToken = localStorage.getItem('user-token');
      if (!savedUserToken) {
        next('/login');
      } else {
        updateState();
        next();
      }
    },
    children: [
      {
        path: '',
        component: () =>
          import('../views/App/Dashboard/Dashboard.vue'),
      },
      {
        path: 'profile',
        component: () => import('../views/App/Profile/index.vue'),
      },
      {
        path: 'pricing',
        component: () => import('../views/App/Pricing/index.vue'),
      },
      {
        path: 'pricing/:id',
        component: () =>
          import('../views/App/Pricing/components/PricingForm.vue'),
      },
      {
        path: 'payment',
        component: () => import('../views/App/Payment/index.vue'),
      },
      {
        path: 'payment/:id',
        component: () =>
          import('../views/App/Payment/components/PaymentForm.vue'),
      },
      {
        path: 'earnings',
        component: () => import('../views/App/Earnings/index.vue'),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
