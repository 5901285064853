export const getConfig = (): { [key: string]: any } => {
  const env = {
    BASE_URL: process.env.VUE_APP_BASE_URL,
    APP_URL: process.env.VUE_APP_URL,
    PAYSTACK_KEY: process.env.VUE_APP_PAYSTACK_KEY,
    FIREBASE_LOCATION_REF: process.env.FIREBASE_LOCATION_REF,
  };
  return env;
};
export const states: string[] = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'Abuja',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun State',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
];

export const categories = [
  'Grocery',
  'Health & Beauty',
  'Home & Office',
  'Phones & Tablets',
  'Computing',
  'Electronics',
  "Women's Fashion",
  "Men's Fashion",
  'Baby Products',
  'Gaming',
  'Sporting Goods',
  'Automobile',
];
