import { reactive } from 'vue';

import { useUnAuthorizedHeaders } from '@/composables/useRequestHeaders';
import { getConfig } from '@/lib/constants';
import { makeRequest, Return } from '@/lib/utils';
import { PickUp, Delivery, Merchant } from '@/types/bookingForm';

type MerchantState = {
  merchant: Merchant | null;
  preferredMerchant?: string;
  jobIds: number[];
};

const state = reactive<MerchantState>({
  merchant: null,
  jobIds: [],
});

const getMerchantInformationBySlug = async (
  slug: string,
): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-profiles/${slug}`,
    'GET',
    headers,
  );
  if (response.ok) {
    state.merchant = response.data;
  }
  return response;
};

const verifyJobCompletionStatus = async (
  id: string,
): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs/confirm/${id}`,
    'GET',
    headers,
  );
  return response;
};

const jobCompletionConfirmation = async (
  id: string,
): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs/confirm/${id}`,
    'PUT',
    headers,
  );
  return response;
};

const getMerchantPricingInformationByState = async (
  pickupState: string,
): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const merchantId = state.merchant?.merchantId;
  const response = await makeRequest(
    `${
      getConfig().BASE_URL
    }/merchant-pricings/${merchantId}?state=${pickupState}`,
    'GET',
    headers,
  );
  return response;
};

const getMerchantSubaccount = async (
  slug: string,
): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const response = await makeRequest(
    `${getConfig().BASE_URL}/merchant-subaccount/${slug}`,
    'GET',
    headers,
  );
  if (response.ok) {
    state.merchant = {
      ...state.merchant,
      subAccountCode: response.data.subAccountCode,
    };
  }
  return response;
};

const resolveOrder = async (details: {
  pickup: PickUp[];
  delivery: Delivery[];
  preferredMerchant?: string;
}): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const merchantId =
    state.merchant?.merchantId || details.preferredMerchant;
  const pickups = details.pickup.map(elm => ({
    placeId: elm.placeId,
    vehicle: elm.vehicle,
    state: elm.state,
    isExpress: elm.isExpress,
  }));
  const deliveries = details.delivery.map(elm => ({
    placeId: elm.placeId,
  }));
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs/resolve`,
    'POST',
    headers,
    { merchantId, pickups, deliveries },
  );
  return response;
};

// create order
const createOrder = async (details: {
  pickup: PickUp[];
  delivery: Delivery[];
  customer: any;
  amount: number;
  paymentReference?: string;
}): Promise<Return> => {
  const headers = useUnAuthorizedHeaders();
  const merchantId =
    state.merchant?.merchantId || state.preferredMerchant;
  const { pickup, delivery, paymentReference, customer } = details;
  const response = await makeRequest(
    `${getConfig().BASE_URL}/jobs`,
    'POST',
    headers,
    {
      merchantId,
      pickup,
      delivery,
      paymentReference,
      customer,
    },
  );
  return response;
};

const updateCurrentOrders = async (details: {
  paymentReference: string;
}): Promise<Return | undefined> => {
  const headers = useUnAuthorizedHeaders();
  const { paymentReference } = details;
  if (state.jobIds.length) {
    const responses = await makeRequest(
      `${getConfig().BASE_URL}/jobs/bulk-update`,
      'PUT',
      headers,
      {
        paymentReference,
        jobIds: state.jobIds
      },
    )
    return responses;
  }
};

const clearCurrentOrders = (): void => {
  state.jobIds = [];
  return;
};

const store = {
  state,
  createOrder,
  updateCurrentOrders,
  clearCurrentOrders,
  resolveOrder,
  getMerchantSubaccount,
  getMerchantInformationBySlug,
  verifyJobCompletionStatus,
  jobCompletionConfirmation,
  getMerchantPricingInformationByState,
};

export default store;
